@use 'sass:map';

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map.merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2,
    ),
    6: (
      $spacer * 3,
    ),
    7: (
      $spacer * 4,
    ),
  ),
  $spacers
);

// Margin and Padding

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
  &.row-not-wrap > * {
    width: auto;
  }
}

.col {
  flex: 1 0 0%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

@media (max-width: 480px) {
  .col {
    flex: 0 0 100%;
  }
  .col-6 {
    width: 100%;
  }
}
