.Tab {
  display: flex;
  justify-content: center;
  appearance: none;
  background: none;
  width: auto;
  margin: 0;
  border: none;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  color: var(--color-text-secondary);
  cursor: pointer;
  border-top-left-radius: var(--border-radius-messages-small);
  border-top-right-radius: var(--border-radius-messages-small);

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &--active {
    cursor: default;
    color: var(--color-primary);

    .platform {
      opacity: 1 !important;
    }
  }

  @media (hover: hover) {
    &:not(&--active):hover {
      background: var(--color-interactive-element-hover);
    }
  }

  @media (max-width: 600px) {
    &:not(&--active):active {
      background: var(--color-interactive-element-hover);
    }
  }

  > span {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .badge {
    min-width: 1.25rem;
    height: 1.25rem;
    //margin-inline-start: 0.5rem;
    //background: var(--color-gray);
    border-radius: 0.75rem;
    padding: 0 0.3125rem;
    //color: white;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 500;
    text-align: center;
    flex-shrink: 0;

    body.is-ios &,
    body.is-macos & {
      line-height: 1.25rem;
    }
  }

  &__badge--active {
    background: var(--color-primary) !important;
  }

  .blocked {
    position: absolute;
    bottom: -0.625rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
  }

  .platform {
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    opacity: 0;
    background-color: var(--color-primary);
    height: 0.25rem;
    width: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
    pointer-events: none;
    box-sizing: content-box;
    transform-origin: left;

    &.animate {
      transition: transform var(--slide-transition);

      body.animation-level-0 & {
        transition: none !important;
      }
    }
  }
}
