.Management .radio-right .Radio .Radio-main {
  &::before {
    right: 1.125rem;
    left: auto;
    top: 0.25rem;
    transform: none;
    background-color: transparent;
    box-shadow: none;
    border: transparent;
  }
}

.Management .radio-right .Radio .Radio-main {
  &::after {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9InRpY2stY2lyY2xlIj4KPGcgaWQ9InRpY2stY2lyY2xlXzIiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTIgMkM2LjQ5IDIgMiA2LjQ5IDIgMTJDMiAxNy41MSA2LjQ5IDIyIDEyIDIyQzE3LjUxIDIyIDIyIDE3LjUxIDIyIDEyQzIyIDYuNDkgMTcuNTEgMiAxMiAyWk0xNi43OCA5LjdMMTEuMTEgMTUuMzdDMTAuOTcgMTUuNTEgMTAuNzggMTUuNTkgMTAuNTggMTUuNTlDMTAuMzggMTUuNTkgMTAuMTkgMTUuNTEgMTAuMDUgMTUuMzdMNy4yMiAxMi41NEM2LjkzIDEyLjI1IDYuOTMgMTEuNzcgNy4yMiAxMS40OEM3LjUxIDExLjE5IDcuOTkgMTEuMTkgOC4yOCAxMS40OEwxMC41OCAxMy43OEwxNS43MiA4LjY0QzE2LjAxIDguMzUgMTYuNDkgOC4zNSAxNi43OCA4LjY0QzE3LjA3IDguOTMgMTcuMDcgOS40IDE2Ljc4IDkuN1oiIGZpbGw9IiMwQTQ5QTUiLz4KPC9nPgo8L2c+Cjwvc3ZnPgo=');
    right: 2.05rem;
    left: auto;
    top: 0.1rem;
    transform: none;
    color: white;
    width: 0;
    height: 0;
  }
}

.Management .permission-list .radio-right .Radio {
  padding-left: 0;
}
