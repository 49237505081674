.ReactionSelectorReaction {
  position: relative;
  min-width: 1.5rem;
  min-height: 1.5rem;

  &__static {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../../assets/All.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  .AnimatedSticker {
    position: absolute;
    top: 0;
    left: 0;
  }

  &--compact {
    min-width: 1.375rem;
    min-height: 1.375rem;
  }

  &--chosen::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background-color: var(--color-background-compact-menu-hover);
  }
}
