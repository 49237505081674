.Management {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-secondary);

  & > .custom-scroll {
    overflow-y: overlay;
    overflow-x: hidden;
  }

  .personal-photo {
    margin-right: 2rem;
    transform: scale(1.25);
  }

  .section-help {
    line-height: 1.2;
    margin: 0 auto 0.75rem;
    p {
      margin-bottom: 0;
    }
    ul {
      list-style-type: decimal;
      padding-left: 1.5rem;
    }
    .title {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }

  .section-info {
    color: var(--color-text-secondary);
    font-size: 0.875rem;
    padding: 0 1.25rem;
    white-space: pre-line;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  textarea.form-control {
    height: auto;
  }

  .radio-group {
    .Radio {
      padding-left: 2.5rem;
      margin-bottom: 1.25rem;

      &:last-child {
        margin-bottom: 0.625rem;
      }
    }

    .Radio-main {
      &::before {
        left: 0.125rem;
        top: 0.25rem;
        transform: none;
      }

      &::after {
        left: 0.3125rem;
        top: 0.4375rem;
        transform: none;
      }

      .subLabel {
        font-size: 0.875rem;
      }
    }
    &.underline {
      .Radio {
        margin-bottom: 0;
      }
      .radio-group-wrap {
        position: relative;
        margin: 0 -1.25rem;
        padding: 0.75rem 1.25rem;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 0;
          box-shadow: 0 -0.01rem 0 0.01rem var(--color-borders);
        }
      }
    }
  }

  &__filter {
    padding: 0 1rem 0.25rem 0.75rem;
    background-color: var(--color-background);
    box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
    margin-bottom: 0.625rem;

    display: flex;
    flex-flow: row wrap;
    flex-shrink: 0;

    overflow-y: auto;
    max-height: 20rem;

    .input {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
      flex-grow: 1;
    }

    .form-control {
      height: 2rem;
      border: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none;
      font-size: 0.9375rem;
    }
  }

  .Spinner {
    margin: 2rem auto;
  }
  &.user-edit {
    .section {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding: 1rem;
    }
    .form-submit {
      flex-grow: 2;
      justify-content: flex-end;
    }
  }
  .image-input {
    border: none;
    margin: 0;
    button {
      border: none;
      box-shadow: none;
    }
  }
}

.Management,
.Profile {
  .section {
    padding: 0.125rem 0.5rem 0.125rem 1.25rem;
    margin-bottom: 0.75rem;
    background-color: var(--color-background);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05),
      0px 2px 10px rgba(0, 0, 0, 0.04);
    &.wide {
      padding: 1.5rem;
    }

    &.no-border {
      border-top: none;
    }

    > .ChatInfo {
      margin: 0 0 2rem !important;

      .title h3 {
        margin-bottom: 0;
      }
    }
    .row {
      justify-content: space-between;
    }
    .section-icon {
      margin: 0 auto 2rem;
    }

    .ListItem:not(.text-trigger) {
      margin-left: -0.75rem;
      .Reaction {
        display: flex;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
      .multiline-item .subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.with-checkbox {
        body.is-ios &::after,
        body.is-android &::after {
          bottom: -1rem;
        }
      }

      &:not(.picker-list-item) .Checkbox {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      &.narrow {
        .Checkbox {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      &.exceptions-member {
        .ChatInfo .status {
          white-space: pre-wrap;
        }
      }
      .ListItem-button {
        padding: 0.5rem 1rem;
        min-height: 3.125rem;
      }
    }

    .label {
      font-size: 0.9375rem;
      display: flex;
      align-items: center;
      .ReactionStaticEmoji {
        width: 1.5rem;
        margin-right: 1rem;
      }
    }

    .section-heading {
      font-size: 0.9375rem;
      color: var(--color-primary);
      margin-top: 0.875rem;
      margin-bottom: 0.25rem;
      &[dir='auto'] {
        text-align: initial;
      }
    }

    .section-help {
      color: var(--color-text-secondary);
      line-height: 1.375rem;

      &[dir='auto'] {
        text-align: initial;
      }
    }

    .section-info_push {
      margin-top: 0.5rem;
    }

    &[dir='rtl'] {
      text-align: right;
    }
    .Button.link {
      font-size: 0.9375rem;
      font-weight: normal;
      i {
        font-size: 1.35rem;
      }
    }
    &.settings-invitation {
      display: block;
      padding-left: 0.75rem;
      .links-list {
        margin-bottom: 0;
      }
    }
    .link-value {
      display: flex;
      justify-content: space-between;
    }
    &.is-group-members {
      //padding-right: 0.125rem;
      //padding-top: 0.125rem;
      padding: 0 0 0 0.75rem;
    }
  }
  .group-link {
    .ListItem {
      font-size: 0.9375rem;
    }
    .badge-counter {
      font-weight: 500;
    }
    .icon-svg {
      margin-right: 1.375rem;
    }
    .price {
      align-items: center;
    }
    // [stroke] {
    //   stroke: var(--color-primary);
    // }
  }
}

.ManageGroupMembers {
  padding: 0.5rem 1rem;
}

.ManageInvites {
  .primary-link {
    position: relative;
  }
  .AvatarEditable {
    margin-top: 0.75rem;
    margin-bottom: 0.875rem;
  }
  .primary-link-input {
    cursor: pointer;
    margin-bottom: 1rem;
    padding-right: 3rem;
  }

  .primary-link-more-menu {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .create-link {
    margin-bottom: 0.5rem;
  }

  .ListItem-button {
    align-items: center;
  }

  .link-status-icon {
    display: block;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 0.5rem;
    position: static !important;
    color: white !important;
    margin-right: 1rem !important;

    &-gray {
      background-color: #707579;
    }

    &-green {
      background-color: #4fae4e;
    }

    &-red {
      background-color: #e17076;
    }

    &-blue {
      background-color: #3390ec;
    }
  }

  .invite-title {
    white-space: nowrap;
  }
}

.ManageInvite {
  .link-name {
    margin-bottom: 1rem;
  }

  .expire-limit {
    margin-top: 1rem;
  }

  .usage-limit {
    margin-top: 1rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .custom-scroll {
    padding-bottom: 4rem;
  }
}

.ManageInviteInfo {
  .copy-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .link-title {
    text-align: center;
  }
}

.ManageJoinRequests {
  .bulk-actions {
    display: flex;
    justify-content: space-around;
  }

  .bulk-action-button {
    width: auto;
    height: auto;
  }
}

.ManageInvite,
.ManageInvites {
  .hint {
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}

.DropdownList {
  display: none;
  transition: 0.25s ease-in-out transform;
  transform: translateY(calc(-100%));
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 1.5rem 0 2.5rem;
  background: var(--color-background);

  &--open {
    transform: translateY(-2rem);
  }
}

.DropdownListTrap {
  height: 0;
  width: 100%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(var(--before-shift-height) + 2.5rem);
    background: var(--color-background);
    content: '';
    z-index: 1;
  }
}

.with-shifted-dropdown {
  .ListItem,
  .section-heading {
    position: relative;
    z-index: 2;
  }

  .without-bottom-shadow {
    box-shadow: none;
    padding-bottom: 0;
  }

  .part {
    background-color: var(--color-background);
    box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
    margin: 0 -1.5rem;
    padding: 0 1.5rem 1rem;
  }

  .section,
  .part {
    position: relative;
    transition: 0.25s ease-in-out transform;

    &.shifted {
      transform: translateY(var(--shift-height));
    }
  }
}

.permission-list,
.switcher-list {
  .row {
    position: relative;
    padding: 0.75rem 0;

    &:not(:first-of-type)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      box-shadow: 0 -0.01rem 0 0.01rem var(--color-borders);
    }
    &:first-of-type {
      padding-top: 0.25rem;
    }
  }
  .row-not-wrap {
    flex-wrap: nowrap !important;
    > * {
      flex-shrink: inherit;
      width: 100%;
      max-width: 100%;
    }
    .switcher-wrap {
      margin-right: 20px;
      width: fit-content;
    }
  }
  .DropdownMenu .icon-svg path {
    fill: var(--color-text-secondary);
  }
}

.is-group-members {
  // width: 100%;
  // max-width: 328px;
  margin: 0.125rem 0.75rem 0.125rem 1.25rem;
  border-radius: 1rem;
}

.is-group-members .ListItem-button {
  padding: 0.5rem 0 0.5rem 0.5rem !important;
}

.row-not-wrap .switch-control {
  width: inherit !important;
  max-width: none !important;
  flex-shrink: unset;
}

.row-not-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}
