@import '../../styles/mixins';

.Modal {
  position: relative;
  z-index: var(--z-modal);
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &.centered {
      align-items: center;
      @media (min-width: 576px) {
        min-height: calc(100% - 3.5rem);
      }
    }
  }

  .modal-dialog {
    position: relative;
    width: 100%;
    min-width: 17.5rem;
    max-width: 35rem;
    max-height: 92vh;
    margin: 1.75rem auto 0;
    background-color: var(--color-background);
    box-shadow: 0 0.625rem 1.875rem var(--color-default-shadow);
    border-radius: var(--border-radius-messages);
    transform: translate3d(0, -1rem, 0);
    transition: transform 0.2s ease, opacity 0.2s ease;

    &.custom-scroll {
      overflow-x: hidden;
      overflow-y: auto;
    }
    body.animation-level-0 & {
      transition: none;
      transform: none !important;
    }

    @media (max-width: 450px) {
      max-width: calc(100vw - 3rem) !important;
    }
  }

  .description-primary {
    padding: 0.75rem;
    background-color: #f0f6ff;
    color: var(--color-primary);
    border-radius: var(--border-radius-default-smaller);
    h3 {
      margin-bottom: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }
    .row {
      font-size: 0.8125rem;
      display: flex;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 0.25rem;
      }
      :first-child {
        color: var(--color-text);
      }
    }
    [stroke] {
      stroke: var(--color-primary);
    }
  }

  .description-adult {
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: var(--border-radius-default-smaller);
    border: 1px solid var(--color-error);
    .banner-adult {
      display: flex;
      justify-content: center;
      padding: 5px 0;
      border-radius: 0.25rem;
      background: linear-gradient(175deg, #ff758f 27.08%, #ef4061 75.52%);
      margin-bottom: 0.625rem;
    }
  }

  .benefits {
    padding: 1rem;
    border-radius: var(--border-radius-default-smaller);
    border: 1px solid rgba(7, 7, 8, 0.12);
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.125rem;
      padding-left: 1.25rem;
      margin-bottom: 0;
    }
    li {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 0;
      background-repeat: no-repeat;
      text-align: left;
      // background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE4IDE5Ij48ZyBzdHJva2U9IiM0NEJFMkUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPjxwYXRoIGQ9Ik05IDE3YzQuMTI1IDAgNy41LTMuMzc1IDcuNS03LjVTMTMuMTI1IDIgOSAyIDEuNSA1LjM3NSAxLjUgOS41IDQuODc1IDE3IDkgMTdaIi8+PHBhdGggZD0ibTUuODEzIDkuNSAyLjEyMiAyLjEyMiA0LjI1My00LjI0NSIvPjwvZz48L3N2Zz4=');
    }
  }

  &.confirm {
    z-index: var(--z-lock-screen);
    .modal-content {
      text-align: center;
      padding: 0.5rem 2.5rem 0;
      .full-name {
        display: flex;
        gap: 0.25rem;
      }
      @media only screen and (max-width: 480px) {
        padding: 0 1rem;
      }
      div:not(.price) {
        line-height: 1.1875rem;
      }
      h2 {
        margin-bottom: 0;
      }

      .amount {
        justify-content: center;
        gap: 0;
        svg {
          margin-left: 0.25rem;
          margin-right: -1px;
        }
      }
      ul {
        list-style-type: decimal;
        padding-left: 1.25rem;
        padding-top: 0.5rem;
        li {
          text-align: left;
        }
      }
    }
  }

  &.info-confirm {
    .modal-content {
      p {
        margin: 0;
      }
    }
  }

  &.delete,
  &.error,
  &.confirm,
  &.pin,
  &.unpin-all {
    .modal-dialog {
      max-width: 25rem;
    }
  }

  &.error {
    .modal-content > div {
      margin-top: 1rem;
    }
  }

  &.narrow {
    .modal-dialog {
      max-width: 20rem;
    }
  }

  &.slim {
    .modal-dialog {
      max-width: 25rem;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(7, 7, 8, 0.2);
  }

  &.transparent-backdrop .modal-backdrop {
    background-color: transparent;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.open .modal-dialog {
    transform: translate3d(0, 0, 0);
  }

  &.closing .modal-dialog {
    transform: translate3d(0, 1rem, 0);
  }

  .modal-header,
  %modal-header {
    display: flex;
    width: 100%;
    align-items: center;
    flex-shrink: 0;
    gap: 0.75rem;
    padding: 0.825rem 2.5rem 0.5rem 3.125rem;
    min-height: 2.825rem;
    > .Button {
      margin-right: 0.25rem;
      position: absolute;
      z-index: 2;
      top: 2px;
      left: 2px;
    }
    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      line-height: 1;
      .fullName {
        font-size: 1.125rem;
        margin-bottom: 0;
      }
    }
    .status {
      font-size: 0.875rem;
      color: var(--color-text-secondary);
    }
    path {
      &:not([stroke]) {
        fill: var(--color-text-secondary);
      }
    }
  }

  .modal-title {
    font-size: 1rem;
    font-weight: 500;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Radio .Radio-main .label {
    font-size: 0.9375rem;
  }

  .modal-header-condensed {
    @extend %modal-header;
    padding: 0.5rem 1.25rem 0 0.9375rem !important;

    .modal-action-button {
      font-size: 0.875rem;
      height: 2.25rem;
      width: auto;
      line-height: 1.625rem;
      margin-left: auto;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      min-width: 5rem;

      &.danger {
        background-color: var(--color-error);
        color: var(--color-white);

        &:hover,
        &:focus,
        &:active {
          background-color: var(--color-error-shade);
        }
      }
    }
  }

  .modal-content {
    width: 100%;
    flex-grow: 1;
    padding: 0.5rem 2.5rem 2.5rem;
    .text-secondary:not(.text-small) {
      font-size: 0.9375rem;
    }
    &.custom-scroll {
      overflow-y: auto;
    }
    p {
      line-height: 1.2;
      margin-bottom: 1rem;
      white-space: pre-line;
      word-wrap: break-word;
    }
    b,
    strong {
      word-break: break-word;
    }
    .description-primary {
      p {
        margin-bottom: 0.5rem;
      }
    }
    .SearchInput {
      input {
        height: 2.25rem;
        padding-left: 2.75rem;
      }
      > .icon {
        top: 9px;
      }
      svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
    .auth-form {
      padding: 0;
    }
    @media (max-width: 480px) {
      padding: 0.5rem 0.5rem 2.5rem;
    }
  }

  .modal-title,
  .modal-content {
    unicode-bidi: plaintext;
    text-align: initial;
  }

  .modal-about {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  .modal-help {
    color: var(--color-text-secondary);
    font-size: 0.9375rem;
    line-height: 1.3;
  }

  .dialog-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .outline {
      color: var(--color-primary);
    }
  }

  .dialog-buttons-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }

  .dialog-checkbox {
    margin: 1rem 0;
  }

  .confirm-dialog-button {
    height: 3rem;
  }

  .dialog-button-spacer {
    flex-grow: 1;
  }
  .Message:not(.own) {
    padding-left: 0;
    margin-bottom: 0;
  }
}

.country-modal,
.category-modal {
  max-height: 17rem;
  @include overflow-y-scroll();
  .Checkbox .Checkbox-main .label {
    flex-wrap: nowrap;
  }
}
