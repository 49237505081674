.MediaViewerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  overflow: hidden;
  z-index: 1;
  padding: 3.25rem 0;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .thumbnail {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > img {
    max-width: 100vw;
    max-height: calc(100vh - 8.25rem);
    object-fit: contain;
    transition: transform 0.2s;
  }

  .spinner-wrapper {
    max-width: 100vw;
    margin: auto;
  }

  .Spinner {
    margin: auto;
  }
  iframe {
    border: none;
  }
}
